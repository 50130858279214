<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="submitHandler">
      <div class="mb-5 2xl:mb-8">
        <div class="mb-5 2xl:mb-6">
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            :rules="validationRules.name"
            tag="div"
            class="relative"
          >
            <t-input
              :value="model.name"
              id="name"
              name="name"
              type="text"
              placeholder="Name*"
              @focus="setInputName('name')"
              @input="setInputValue($event,'name')"
              @click.stop
            />

            <div class="absolute font-medium text-xs text-white">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <div class="mb-5 2xl:mb-6">
          <ValidationProvider
            v-slot="{ errors }"
            name="Company"
            :rules="validationRules.company"
            tag="div"
            class="relative"
          >
            <t-input
              :value="model.company"
              id="company"
              name="company"
              type="text"
              placeholder="Company*"
              @focus="setInputName('company')"
              @input="setInputValue($event,'company')"
              @click.stop
            />

            <div class="absolute font-medium text-xs text-white">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <div class="mb-5 2xl:mb-6">
          <ValidationProvider
            v-slot="{ errors }"
            name="Job Title"
            :rules="validationRules.job"
            tag="div"
            class="relative"
          >
            <t-input
              :value="model.job"
              id="job"
              name="job"
              type="text"
              placeholder="Job title*"
              @focus="setInputName('job')"
              @input="setInputValue($event,'job')"
              @click.stop
            />

            <div class="absolute font-medium text-xs text-white">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <div class="mb-5 2xl:mb-6">
          <ValidationProvider
            v-slot="{ errors }"
            name="Email"
            :rules="validationRules.email"
            tag="div"
            class="relative"
          >
            <t-input
              :value="model.email"
              id="emailAddress"
              name="emailAddress"
              type="email"
              placeholder="Email*"
              @focus="setInputName('email')"
              @input="setInputValue($event,'email')"
              @click.stop
            />

            <div class="absolute font-medium text-xs text-white">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <div class="mb-5 2xl:mb-8">
          <ValidationProvider
            v-slot="{ errors }"
            name="Phone"
            :rules="validationRules.phone"
            tag="div"
            class="relative"
          >
            <phone-input
              :value="model.phone"
              id="phone"
              name="phone"
              placeholder="Phone*"
              @focus="setInputName('phone')"
              @input="setInputValue($event,'phone')"
            />

            <div class="absolute font-medium text-xs text-white">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          name="Agree"
          :rules="validationRules.checked"
          tag="div"
          class="relative"
        >
          <div class="flex items-center">
            <input
              v-model="checked"
              id="contactCheckbox"
              type="checkbox"
              class="border-gray-100 rounded w-8 h-8 text-orange-600 bg-white"
            />

            <label
              for="contactCheckbox"
              class="ml-5 text-base 2xl:text-xl font-medium text-white"
            >
             I agree to the Digital Manufacturing Ireland's <a href="https://www.dmireland.org/privacy-notice" target="_blank" style="text-decoration: underline;">Data Protection Policy</a>
            </label>
          </div>

          <div class="absolute font-medium text-xs text-white">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </div>

      <div class="text-right">
        <t-button
          type="submit"
          :disabled="invalid"
        >
          <i class="icon-mail mr-2" />
          Send
        </t-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store';
import { auth as authApi } from '@/services/api';
import PhoneInput from '@/components/phone-input.vue';

function getValidationRules() {
  return {
    name: {
      required: true,
    },
    job: {
      required: true,
    },
    company: {
      required: true,
    },
    email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
      phone: true,
    },
    checked: {
      checked: true,
    },
  };
}
const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => ((isMobile.Android()
|| isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows())),
};

export default {
  name: 'ContactForm',
  components: { PhoneInput },
  data() {
    return {
      validationRules: getValidationRules(),
      isTouchDevice: !!('ontouchstart' in window),
      isMobile: isMobile.any(),
    };
  },
  computed: {
    ...mapGetters([
      getters.content.experienceUid,
      getters.contactForm.selected,
      getters.contactForm.model,
    ]),
    checked: {
      get() {
        return this[getters.contactForm.model].checked;
      },
      set(value) {
        this[actions.contactForm.setModelInput]({ inputName: 'checked', inputValue: value });
      },
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.clearAll,
      actions.contactForm.setCurrentInputKeyboard,
      actions.contactForm.clearModel,
      actions.contactForm.setModelInput,
    ]),
    async submitHandler() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      const payload = {
        experience: this.experienceUid,
        ...this.model,
        media: this.selected.map((media) => media.uid),
      };

      try {
        await fetch(process.env.VUE_APP_API_CREATE_LEAD_PATH, {
          method: 'POST',
          headers: authApi.getAuthHeader(),
          body: JSON.stringify(payload),
        });

        this.onConfirm();

        this[actions.contactForm.clearAll]();
        this[actions.contactForm.clearModel]();
        this.$refs.observer.reset();
      } catch (e) {
        console.error(e);
      }
    },
    onConfirm() {
      this.$emit('on-confirm', this.model);
    },
    setInputName(inputName) {
      if (this.isTouchDevice && this.isMobile) return;
      this[actions.contactForm.setCurrentInputKeyboard](inputName);
    },
    setInputValue(inputValue, inputName) {
      this[actions.contactForm.setModelInput]({ inputName, inputValue });
    },
  },
};
</script>
